import { Input } from "@fscrypto/ui";
import { Search, XIcon } from "lucide-react";
import { useEffect, useState } from "react";

interface SearchTermProps {
  onChange: (val: string) => void;
  value?: string;
}

const SearchTerm = ({ onChange, value }: SearchTermProps) => {
  const [inputValue, setInputValue] = useState(value ?? "");

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Input
      prefix={<Search className="size-4" />}
      suffix={
        inputValue.length ? (
          <XIcon onClick={() => handleChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>)} />
        ) : undefined
      }
      size="md"
      data-testid="discover-search"
      placeholder="Search..."
      className="mr-auto w-full md:w-1/3"
      onChange={handleChange}
      value={inputValue}
    />
  );
};

export default SearchTerm;
